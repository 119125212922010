import { collection, doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type SlackChannel } from "./CloudAnalytics";
import { type CustomerModel } from "./Customer";
import { type UserModel } from "./User";

export type ObjectValues<T> = T[keyof T];

export const ActionTypes = {
  TRIGGER: "trigger",
  ACTION: "action",
} as const;

export type ActionType = ObjectValues<typeof ActionTypes>;

export const ActionParamTypes = {
  SELECT: "select",
  NUMBER: "number",
  TEXT: "text",
  DATE: "date",
} as const;

export type ActionParamType = ObjectValues<typeof ActionParamTypes>;

export type ActionSimpleValueType = string | number | boolean | Timestamp;

export type ActionValueType =
  | ActionSimpleValueType
  | Record<string, ActionSimpleValueType>
  | Record<string, ActionSimpleValueType>[];

export type ActionParameters = {
  key: string;
  type: ActionParamType;
  options?: unknown[];
  default?: ActionValueType;
  label: string;
  required?: boolean;
};

export type NodeDisplayPosition = {
  x: number;
  y: number;
};

export type NodeDisplayAttributes = {
  position: NodeDisplayPosition;
};

export const NODE_STATUS = {
  VALIDATED: "validated",
  PENDING: "pending",
  ERROR: "error",
  NEW: "new",
} as const;

export type NodeStatus = ObjectValues<typeof NODE_STATUS>;

export type NodeTransition = {
  value?: unknown;
  targetNodeId: string;
  parentNodeId?: string;
  label?: string;
};

export type NodeTransitionList = NodeTransition[] | null;

export const CLOUD_FLOW_CREATION_STATUS = {
  DRAFT: "draft",
  PENDING: "pending",
  PUBLISHED: "published",
  INACTIVE: "inactive",
  NEW: "new",
} as const;

export type CloudflowCreationStatus = ObjectValues<typeof CLOUD_FLOW_CREATION_STATUS>;

export enum CloudflowExecutionStatus {
  PENDING = "pending",
  RUNNING = "running",
  COMPLETE = "complete",
  PENDING_APPROVAL = "pending-approval",
  FAILED = "failed",
}

export type FlowCollaborator = {
  email: string;
  role: "owner" | "editor" | "viewer";
};

// TODO: during transition from templates to bluprints we adding additional type "blueprint"
// to distinguish between them. After transition we can remove one of them
export type CloudflowType = "preset" | "custom" | "blueprint";

export type FlowCollaborators = FlowCollaborator[];

export const APP_KEY = {
  AWS: "aws",
  GCP: "gcp",
  DOIT: "doit",
  INTERNAL: "internal",
} as const;

export type AppKey = ObjectValues<typeof APP_KEY>;

export enum ComparisonOperator {
  CONTAINS = "contains",
  NOT_CONTAINS = "not contains",
  EQUALS = "==",
  NOT_EQUALS = "!=",
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL = ">=",
  LESS_THAN_OR_EQUAL = "<=",
  IN = "in",
  NOT_IN = "not in",
  IS_NULL = "is null",
  IS_NOT_NULL = "is not null",
}

export enum NotificationProviders {
  SLACK = "slack",
  EMAIL = "email",
}

export type ReferencedNodeValue = {
  referencedNodeId: string;
  referencedField: string[];
};

export enum ConditionExpressionType {
  STATIC = "STATIC",
  REFERENCED = "REFERENCED",
}

type ConditionExpressionBase = {
  type: ConditionExpressionType;
  field: string[];
  comparisonOperator: ComparisonOperator;
};

export type ConditionStaticValueExpression = ConditionExpressionBase & {
  type: ConditionExpressionType.STATIC;
  value: unknown;
};

export type ConditionReferencedValueExpression = ConditionExpressionBase & {
  type: ConditionExpressionType.REFERENCED;
  value: ReferencedNodeValue;
};

export function isConditionStaticValueExpression(
  condition: ConditionExpression
): condition is ConditionStaticValueExpression {
  return condition.type === ConditionExpressionType.STATIC;
}

export function isConditionReferencedValueExpression(
  condition: ConditionExpression
): condition is ConditionReferencedValueExpression {
  return condition.type === ConditionExpressionType.REFERENCED;
}

export type ConditionExpression = ConditionStaticValueExpression | ConditionReferencedValueExpression;

@subCollection("actionEntities")
export class ActionEntityModel {
  name!: string;

  key!: string;

  description!: string;

  type!: ActionType;

  appKey!: AppKey;

  parameters!: ActionParameters[];

  createdAt!: Timestamp;

  updatedAt!: Timestamp;
}

export enum CloudFlowProvider {
  AWS = "AWS",
  GCP = "GCP",
  DoiT = "DoiT",
}

export type OperationPointer = { provider: CloudFlowProvider; service: string; version: string; id: string };

export type NodeConfigApiParametersBase = {
  provider: CloudFlowProvider;
  operation: OperationPointer;
  formValues: object;
  configurationValues: Record<string | number, unknown>;
};

export type AWSNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.AWS;
  configurationValues: { accountId: string; regions?: string[] };
};

export type GCPNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.GCP;
  configurationValues: { organization: string; serviceAccount: string };
};

export type DoiTNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.DoiT;
};

export type NodeConfigApiParameters =
  | AWSNodeConfigApiParameters
  | GCPNodeConfigApiParameters
  | DoiTNodeConfigApiParameters;

export type ConditionExpressionsGroup = {
  conditions: ConditionExpression[];
};

export type NodeConfigConditionalParameters = {
  referencedNodeId: string;
  referencedField: string[];
  conditionGroups: ConditionExpressionsGroup[];
};

export enum Frequencies {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Custom = "Custom",
}

export enum CustomFrequencies {
  Day = "Day",
  Week = "Week",
  Month = "Month",
}

export interface DateObjectUnits {
  // a year, such as 1987
  year?: number;
  // a month, 1-12
  month?: number;
  // a day of the month, 1-31, depending on the month
  day?: number;
  // an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
  weekday?: WeekdayNumbers;
  // hour of the day, 0-23
  hour?: number;
  // minute of the hour, 0-59
  minute?: number;
}

export type WeekdayNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type NodeConfigScheduleTriggerParameters = {
  timeZone: string;
  startDate: DateObjectUnits;
  time: DateObjectUnits;
  frequency: Frequencies;
  customFrequency: CustomFrequencies;
  customFrequencyAmount: number;
};

export enum NodeTransformationType {
  CONCATENATION = "concatenation",
  JOIN = "join",
  FIRST_ITEM = "first-item",
  LAST_ITEM = "last-item",
  EXTRACT = "extract",
  UPPER_CASE = "upper-case",
  LOWER_CASE = "lower-case",
}

export type NodeTransformationConcatenation = {
  type: NodeTransformationType.CONCATENATION;
  newFieldName: string;
  payload: (string | ReferencedNodeValue)[];
};

export type NodeTransformationUpperCase = {
  type: NodeTransformationType.UPPER_CASE;
  newFieldName: string;
  payload: ReferencedNodeValue;
};

export type NodeTransformationLowerCase = {
  type: NodeTransformationType.LOWER_CASE;
  newFieldName: string;
  payload: ReferencedNodeValue;
};

export type NodeTransformationFirstItem = {
  type: NodeTransformationType.FIRST_ITEM;
  newFieldName: string;
  payload: ReferencedNodeValue;
};

export type NodeTransformationLastItem = {
  type: NodeTransformationType.LAST_ITEM;
  newFieldName: string;
  payload: ReferencedNodeValue;
};

export type FlagOptions = {
  global: boolean;
  ignoreCase: boolean;
  multiline: boolean;
};

export type NodeTransformationExtract = {
  type: NodeTransformationType.EXTRACT;
  newFieldName: string;
  payload: ReferencedNodeValue;
  regexp: string;
  flags: FlagOptions;
};

export type NodeTransformationJoin = {
  type: NodeTransformationType.JOIN;
  newFieldName: string;
  payload: ReferencedNodeValue;
  primaryKey?: ReferencedNodeValue;
  foreignKey?: ReferencedNodeValue;
};

export type NodeTransformation =
  | NodeTransformationConcatenation
  | NodeTransformationJoin
  | NodeTransformationFirstItem
  | NodeTransformationLastItem
  | NodeTransformationExtract
  | NodeTransformationUpperCase
  | NodeTransformationLowerCase;

export type NodeConfigTransformParameters = {
  referencedNodeId: string;
  referencedField: string[];
  transformations: [] | [NodeTransformation]; // as of now we support only one transformation per node
};

export enum CloudFlowNodeType {
  START_STEP = "startStep",
  GHOST = "ghost",
  TRIGGER = "triggerNode",
  ACTION = "actionNode",
  CONDITION = "conditionNode",
  FILTER = "filterNode",
  MANUAL_TRIGGER = "manualTrigger",
  LOOP = "loop",
  TRANSFORMATION = "transformation",
}

// Map provides better type narrowing support than conditional constraint
export type NodeParametersMap = {
  [CloudFlowNodeType.ACTION]: NodeConfigApiParameters;
  [CloudFlowNodeType.CONDITION]: NodeConfigConditionalParameters;
  [CloudFlowNodeType.FILTER]: NodeConfigConditionalParameters;
  [CloudFlowNodeType.TRIGGER]: NodeConfigScheduleTriggerParameters;
  [CloudFlowNodeType.TRANSFORMATION]: NodeConfigTransformParameters;
  [CloudFlowNodeType.MANUAL_TRIGGER]: never;
  [CloudFlowNodeType.GHOST]: never;
  [CloudFlowNodeType.LOOP]: never;
  [CloudFlowNodeType.START_STEP]: never;
};

export type NodeParameters<T extends CloudFlowNodeType = CloudFlowNodeType> = NodeParametersMap[T];

export function isNodeConfigApiParameters(
  params:
    | NodeConfigScheduleTriggerParameters
    | NodeConfigApiParameters
    | NodeConfigConditionalParameters
    | NodeConfigTransformParameters
): params is NodeConfigApiParameters {
  return "operation" in params && "provider" in params;
}

export enum TimeUnits {
  Hours = "Hours",
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
}

export type SlackRecipient = {
  notificationProvider: NotificationProviders.SLACK;
  slackChannels: SlackChannel[];
};

export type EmailRecipient = {
  notificationProvider: NotificationProviders.EMAIL;
  emails: string[];
};

export type Recipient = SlackRecipient | EmailRecipient;

export type ApprovalConfig = {
  required: boolean;
  recipient: Recipient;
  message: string;
  rejectApprovalAfterTime: boolean;
  rejectTimeValue?: number;
  rejectTimeUnit?: TimeUnits;
};

export interface INodeModel<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> {
  name: string;
  display: NodeDisplayAttributes;
  type: TNodeType;
  status: NodeStatus;
  parameters?: NodeParameters<TNodeType>;
  approval?: ApprovalConfig;
  transitions: NodeTransitionList;
  appKey: AppKey;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  createdBy?: Reference<UserModel>;
  errorMessages?: Record<string, string>;
}

@subCollection("nodes")
export class NodeModel<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> implements INodeModel {
  name!: string;

  display!: NodeDisplayAttributes;

  type!: TNodeType;

  status!: NodeStatus;

  approval?: ApprovalConfig;

  parameters!: NodeParameters<TNodeType>;

  errorMessages?: Record<string, string>;

  transitions!: NodeTransitionList;

  appKey!: AppKey;

  createdAt!: Timestamp;

  updatedAt!: Timestamp;

  createdBy?: Reference<UserModel>;
}

@subCollection("cloudflowEntities")
export class CloudflowEntityModel {
  name!: string;

  description!: string;

  publishedBy?: Reference<UserModel>;

  customer!: Reference<CustomerModel>;

  status!: CloudflowCreationStatus;

  createdAt!: Timestamp;

  createdBy!: Reference<UserModel>;

  updatedAt?: Timestamp;

  collaborators!: FlowCollaborators;

  firstNode!: Reference<NodeModel>;

  lastExecuted?: Timestamp;

  schedule?: {
    scheduled: boolean;
    nextRun: Timestamp;
    cron: string;
  };

  subCollections?: {
    nodes: NodeModel;
  };

  type!: CloudflowType;

  tags?: Record<string, string[]>;
}

@subCollection("cloudflowExecutions")
export class CloudflowExecutionModel {
  cloudflowId!: string;

  cloudflowName!: string;

  customer!: Reference<CustomerModel>;

  status!: CloudflowExecutionStatus;

  triggerTime!: Timestamp;

  triggeredBy!: string; // "scheduler" | user email

  startTime?: Timestamp;

  endTime?: Timestamp;

  error?: string;

  failedNode?: Reference<NodeModel>;

  lastExecutedNode?: Reference<NodeModel>;

  subCollections?: {
    executionNodes: ExecutionNodeModel;
  };
}

@doc("actions")
export class ActionDoc {
  subCollections?: {
    actionEntities: ActionEntityModel;
  };
}

@doc("cloudflows")
export class CloudflowDoc {
  subCollections?: {
    cloudflowEntities: CloudflowEntityModel;
    cloudflowExecutions: CloudflowExecutionModel;
  };
}

@collection("cloudflowEngine")
export class CloudflowEngineModel {
  docs?: {
    actions: ActionDoc;
    cloudflows: CloudflowDoc;
  };
}

export enum NodeExecutionStatus {
  PENDING_APPROVAL = "pending-approval",
  FAILED = "failed",
  REJECTED = "rejected",
  APPROVAL_TIMED_OUT = "approval-timed-out",
  PENDING = "pending",
  IN_PROGRESS = "in-progress",
  COMPLETE = "complete",
}

@subCollection("executionNodes")
export class ExecutionNodeModel {
  executionId!: string;

  nodeId!: string;

  nodeName!: string;

  startedAt?: Timestamp;

  finishedAt?: Timestamp;

  status!: NodeExecutionStatus;

  error?: string;

  approvalDetails?: ApprovalDetails;
}

export class ApprovalDetails {
  approvedVia?: NotificationProviders;

  action?: ApprovalAction;

  approvedBy?: string;

  receivedAt?: Timestamp;

  sentAt!: Timestamp;
}

export enum ApprovalAction {
  APPROVED = "approved",
  REJECTED = "rejected",
}
